/* EditBar.module.css */

.editBar {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    max-width: 600px; /* Ajustado para caber os elementos */
    background-color: #1E2022;
    padding: 8px 12px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
}

/* Estilos para os controles */

.select {
    background-color: #1E2022;
    color: #FFFFFFB3;
    border: none;
    padding: 4px 8px;
    border-radius: 4px;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 500;
    outline: none;
    cursor: pointer;
    width: auto; /* Largura automática para economizar espaço */
}

.colorPicker {
    width: 32px;
    height: 28px;
    padding: 0;
    border-radius: 4px;
    border: none;
}

.alignmentButtons {
    display: flex;
    gap: 6px;
}

.alignButton {
    background-color: #1E2022;
    color: #FFFFFFB3;
    border: none;
    padding: 6px;
    border-radius: 4px;
    font-size: 16px; /* Ajuste de tamanho para os ícones */
    cursor: pointer;
    outline: none;
}

.alignButton:hover, .select:hover {
    background-color: #292D2E;
}

/* EditBar.module.css */

.colorPickerContainer {
    position: relative;
    display: inline-block;
}

.colorButton {
    display: flex;
    align-items: center;
    background-color: #1E2022;
    border: none;
    padding: 6px 10px;
    border-radius: 8px;
    cursor: pointer;
    color: #FFFFFFB3;
    font-size: 14px;
    gap: 8px;
    transition: background 0.2s ease;
}

.colorButton:hover {
    background-color: #3A3D41;
}

.colorIcon {
    font-size: 18px;
}

.colorPreview {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 1px solid #3A3D41;
}

.colorPickerPopover {
    position: absolute;
    bottom: 40px;
    left: 0;
    z-index: 2;
    padding: 10px;
    background-color: #1E2022; /* Fundo escuro */
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
}

.customColorPicker {
    width: 200px;
}



