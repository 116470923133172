.avatarWrapper {
  width: 40px;
  height: 40px;
  background-color: #4285F4;
  color: #D4D1CB;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 22px;
  font-weight: 600;
  cursor: pointer;
}