.buttonWrapper {
    background: #1E2022;
    color: #D4D1CB;
    height: 40px;
    padding: 8px 18px;
    border: none;
    border-radius: 10px;
    display: flex;
    align-items: center;
    font-family: var(--font-primary), sans-serif;
    font-size: var(--font-size-medium);
    font-weight: 700;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.buttonWrapper:hover {
    background-color: #292D2E;
}

.buttonWrapper svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    stroke-width: 2;
}