/* ImageEditBar.module.css */
.editBar {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 600px;
    background-color: #1E2022;
    padding: 8px 12px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    height: 50px;
}

.controlButton {
    background: transparent;
    border: none;
    color: #FFFFFFB3;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
}

.controlButton:hover {
    color: #FFFFFF;
}

.sliderContainer {
    display: flex;
    align-items: center;
    gap: 6px;
}

.icon {
    color: #FFFFFFB3;
    font-size: 16px;
}

.icon:hover {
    color: #FFFFFF;
}

.slider {
    width: 100px;
    height: 10px; /* Largura aumentada */
    -webkit-appearance: none;
    appearance: none;
    background: linear-gradient(to right, #4CAF50 50%, #3A3D41 50%);
    border-radius: 5px;
    outline: none;
    position: relative;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: #FFFFFF;
    cursor: pointer;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}

.slider::-moz-range-thumb {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #FFFFFF;
    cursor: pointer;
}

.colorPickerContainer {
    position: relative;
    display: inline-block;
}

.colorButton {
    display: flex;
    align-items: center;
    background-color: #1E2022;
    border: none;
    padding: 6px 10px;
    border-radius: 8px;
    cursor: pointer;
    color: #FFFFFFB3;
    font-size: 14px;
    gap: 8px;
    transition: background 0.2s ease;
}

.colorButton:hover {
    background-color: #3A3D41;
}

.colorPreview {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 1px solid #3A3D41;
}

.colorPickerPopover {
    position: absolute;
    bottom: 40px;
    left: 0;
    z-index: 2;
    padding: 10px;
    background-color: #1E2022;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
}

.customColorPicker {
    width: 200px;
}
