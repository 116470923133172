/* Canvas.module.css */

.canvasContainer {
    position: relative;
    width: 94vw;
    height: 80vh;
    background-color: #181A1B;
    border: 2px solid #1E2022;
    margin: 12px 42px;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 20px;
}

.canvas {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.itemContainer {
    position: absolute;
    cursor: move;
}

.sideButtons {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 10;
}

.sideButton {
    background-color: #1E2022;
    color: white;
    border: none;
    margin-bottom: 10px;
    cursor: pointer;
    padding: 10px 25px;
    border-radius: 14px;
    font-size: 12px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    transition: background 0.3s ease;
}

.sideButton:hover {
    background-color: #292D2E;
}

.zoomedCanvas {
    position: absolute;
    transform-origin: 0 0;
}

.cursorGrab {
    cursor: grab;
}

.cursorGrabbing {
    cursor: grabbing;
}

.fullscreenButton {
    position: absolute;
    bottom: 20px;
    left: 20px;
    background-color: #1E2022;
    color: white;
    border: none;
    cursor: pointer;
    padding: 10px 25px;
    border-radius: 14px;
    font-size: 12px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    transition: background 0.3s ease;
}

.fullscreenButton:hover {
    background-color: #292D2E;
}

.saveButtonStandalone {
    position: absolute;
    bottom: 10px;
    right: 20px;
    min-width: 160px;
    background-color: #1E2022;
    color: white;
    border: none;
    margin-bottom: 10px;
    cursor: pointer;
    padding: 10px 25px;
    border-radius: 14px;
    font-size: 12px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    transition: background 0.3s ease;
}

.saveButtonStandalone:hover {
    background-color: #3A3D41;
}
