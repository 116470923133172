.headerWrapper {
    background-color: #1B1D1E;
    padding: 0 40px;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 32px;
    color: #ffffff;
}

.headerActions {
    display: flex;
    align-items: center;
    gap: 28px;
}
