.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.modalWrapper {
    background-color: #1B1D1E;
    border-radius: 12px;
    padding: 24px;
    width: 400px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modalTitle {
    font-family: var(--font-primary);
    font-size: 32px;
    font-weight: 600;
    color: var(--text-white);
    margin: 0 0 24px 12px;
    text-align: left;
    width: 100%;
}

.input {
    width: calc(100% - 40px);
    padding: 12px;
    margin-bottom: 24px;
    border: 1px solid #3a3a3a;
    border-radius: 6px;
    font-size: var(--font-size-medium);
    font-family: var(--font-primary);
    background-color: #1e1e1e;
    color: var(--text-white);
    outline: none;
}

.input::placeholder {
    color: #8a8a8a;
}

.input:focus {
    border-color: var(--primary);
}

.buttonContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.button {
    padding: 12px 16px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-family: var(--font-primary);
    font-size: var(--font-size-medium);
    font-weight: 500;
    background-color: #2c2c2c;
    color: var(--text-white);
    flex: 1;
    margin: 0 8px;
}

.primaryButton {
    background-color: #4285F4;
}

.button:hover {
    background-color: #3a3a3a;
}

.primaryButton:hover {
    background-color: #699bf1;
}
