/* AudioEditBar.module.css */
.editBar {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 600px;
    background-color: #1E2022;
    padding: 8px 12px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    height: 50px;
}

.controlButton {
    background: transparent;
    border: none;
    color: #FFFFFFB3;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
}

.controlButton:hover {
    color: #FFFFFF;
}

.recording {
    color: #FF4C4C; /* Botão de gravação em vermelho */
}

.volumeControl {
    display: flex;
    align-items: center;
    gap: 6px;
}

.slider {
    width: 100px;
    height: 10px;
    -webkit-appearance: none;
    appearance: none;
    background: linear-gradient(to right, #4CAF50 50%, #3A3D41 50%);
    border-radius: 5px;
    outline: none;
    position: relative;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: #FFFFFF;
    cursor: pointer;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}

.slider::-moz-range-thumb {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: #FFFFFF;
    cursor: pointer;
    border: none;
}

.duration {
    color: #FFFFFFB3;
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-left: 8px;
}

.recordingTime {
    color: #FF4C4C; /* Tempo em vermelho durante a gravação */
}

.blinkingDot {
    height: 8px;
    width: 8px;
    background-color: #FF4C4C;
    border-radius: 50%;
    margin-right: 4px;
    animation: blink 1s infinite;
}

@keyframes blink {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
}
