.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.modalWrapper {
    background-color: #1B1D1E;
    border-radius: 8px;
    padding: 20px;
    width: 300px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 15vh;
    right: 3vw;
    z-index: 1000;
}

.userList {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.userItem {
    display: flex;
    align-items: center;
    padding: 12px 8px;
    color: var(--text-white);
    font-family: var(--font-primary), serif;
    font-size: var(--font-size-medium);
    font-weight: 500;
    cursor: pointer;
    border-radius: 6px;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.userItem:hover {
    background-color: #292D2E;
    transform: scale(1.02);
}

.userCircle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    background-color: #4285F4;
    align-items: center;
    font-size: var(--font-size-medium);
    font-weight: 700;
    margin-right: 12px;
    color: white;
}

.userName {
    font-family: var(--font-primary), serif;
    font-size: var(--font-size-medium);
    font-weight: 500;
    color: var(--text-white);
}

.divider {
    border: 1px solid #292D2E;
    margin: 8px 0;
}
